import React, { useEffect } from 'react'
import { graphql } from 'gatsby'
import Modal from 'react-modal'

import { Seo } from '../components/base'
import { Layout, OfferOverview } from '../components'
import { getOfferPdf, marketOfferPath } from '../utils'
import { navigate } from 'gatsby'
import {
  getLocale,
  getLocaleMarketUrl,
  getLocaleUrl,
} from '../utils/localeUtils'
import { getLocaleMarket } from '../utils/getLocaleMarket'

Modal.setAppElement('#___gatsby')

const OfferPage = ({ data, pageContext, location }) => {
  const languages = { pageContext }
  const locale = getLocale()
  let myMarket = getLocaleMarket()

  const offerHandouts = data?.sanityOfferPage.nodes[0].offerHandouts

  const offerHandout = getOfferPdf(
    offerHandouts,
    myMarket?.handoutVariant,
    myMarket?.marketType
  )
  // const dividingBannerContent = {
  //   data: {
  //     headline: 'Unser Prospekt bequem herunterladen',
  //     text: 'Entdecken Sie exklusive Angebote und Aktionen – einfach Prospekt herunterladen, stöbern und inspirieren lassen!',
  //     image: {
  //       image: {
  //         asset: {
  //           gatsbyImageData: {
  //             images: {
  //               sources: [],
  //               fallback: {
  //                 src: 'https://cdn.sanity.io/images/or2m2oma/staging/a9c72812619eec13339c9606c40fc768feae4966-368x280.png?w=368&h=280&auto=format',
  //                 srcSet:
  //                   'https://cdn.sanity.io/images/or2m2oma/staging/a9c72812619eec13339c9606c40fc768feae4966-368x280.png?rect=0,1,368,279&w=320&h=243&auto=format 320w,\nhttps://cdn.sanity.io/images/or2m2oma/staging/a9c72812619eec13339c9606c40fc768feae4966-368x280.png?w=368&h=280&auto=format 368w',
  //                 sizes: '(min-width: 368px) 368px, 100vw'
  //               }
  //             },
  //             layout: 'constrained',
  //             width: 368,
  //             height: 280,
  //             placeholder: {
  //               fallback:
  //                 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAAAsTAAALEwEAmpwYAAAD/ElEQVQ4jWXRa0ybZRQH8KctKxsa4zITzYhmHyTGxCUapx/UbJGAFzYNGLLNBWx0OCxzoQUK5aUUKKUrjEIvjNLLoC0ta99SVhRa20JHR9u141ZqCmOIkG1Gs8SNLkGZ0ueYboISTnK+nOT8cvI/CP2vZsLjaGn5Ppr2j6CZST9lKuDYFXJq0wb1Nc+0NTP2ySTF6cNW2Ss+h/ENh7U7w6hRpP25MISmXTqkVHaiHeUku1BscgRdc5FUi1r0Undb1SfqC2ymqo1dKW89J1S2n5VZ1FU6cxffpJHUlF9s5h1I7p1+l4ZIc99OsF/biKZ8NuQaUKdoRMVZcj6jXy5kLmrllXd6uzj3LqvOrVq05Wv6i0RcI6kmu6X1h5bjQEnu2knDTtAoZaGA04i+M7akyLnHchS8vKCuvQSbteVAahigVxyFDhkDVF2CDZNW4ia1rUdamnjUqasjiOxRPEEAYKtt3Q0IbwAKevqoiroTh5VNBS6DnPmXTl6IVfIjCUnrWwlubS7mCyo2OqXCgF7RlFNXzkwZNXUgQ4doJ3gjOLo1Ewoq3iSqim1EZdE6t/o4NIg/xu3yPCwUMHBNZRFImtgRnbL+uE4jSV2IOJC1t20nmJ2VhdC+9MfzL6u5r+YzS/SfFRev5bHOwFlxKb7UJ8SkXoQNCh7YDMKbbofytMdLPn3n92soGDRtgRQAoAEANZFIUDIrWAi9/x76kFt2IJvD6jjG5cSPEhwovFCPe4b02Oc24DFSAeEr6pVZr5UdDrn2rsYjaGVuFNHp9McgFQB2A8AejHFKjpBAL7AKUXY9Z38mwRblE9x7X1TVAEPAx8orehwct+NppxnmnfZff/KP1UUmAs8DrKNHv61su3DXv2Bq7PYyLTk/JRU992kth1tSQ9xt5AmALxbhHrsGTwT78LyXhEX/6P2l6JRkNjL54mZkBw8e3JYhDWNMj6+tpQEAnTDqnmVKeCWNMmKpWyEEU6cM2+0KHPS3Jma8nRDxDT6ci4RVsR8jGZuGWHz+CYgx3mzawz/W9gBAaq3R+FRJO7eAI2XFeEI2tPIF2GpSw42QGYfcevBYtOshr8N8cy76ehp1L4LEL2jE497+5SS6cPd2MlPKV3IJ/XNxdW5uQ+lkZmkRnKzkQO9Af2I+Ov337A+uR65LmtVxh52cn4u+7ba6k7GhiXDovws3UZSehmwBH/pWKaWdbGk4nM2vsBwq+2Y+X8SP9ns913++tThyyx+2j5NWvd81zJ6NTGQMW4YoDxKArgfGt1+Y7MtX3Sj+YBXZfF7KGVnzy3mC6qIPCDbxddt55qDXc2IuFvsoOj35zpjL+dr3A+R+0ty3m1PGQQVFLKRRdaF/AM9Tjv9EomaZAAAAAElFTkSuQmCC'
  //             }
  //           },
  //           url: 'https://cdn.sanity.io/images/or2m2oma/staging/a9c72812619eec13339c9606c40fc768feae4966-368x280.png',
  //           altText: null
  //         }
  //       }
  //     },

  //     imagePosition: 'left',
  //     customCardColor: {
  //       cardColor: { hex: '#04643e' },
  //       textColor: 'bright'
  //     },
  //     bioButton: {
  //       buttonCta: 'Prospekt herunterladen',
  //       customLink: offerHandout,
  //       buttonIcon: 'SingleArrowRight',
  //       buttonIconPosition: 'right',
  //       customPath: null,
  //       customLinkBlank: true,
  //       offerPdf: null,
  //       openSidebar: null
  //     },
  //     marketReference: {}
  //   },
  //   market: myMarket,
  //   pageContext
  // }

  let dividingBannerContent = data?.sanityOfferPage.nodes[0].dividingBanner

  dividingBannerContent = {
    ...dividingBannerContent,
    marketReference: {},
    bioButton: {
      ...dividingBannerContent?.bioButton,
      customPath: null,
      customLinkBlank: true,
      offerPdf: null,
      customLink: offerHandout,
    },
  }
  console.log(dividingBannerContent)
  useEffect(() => {
    const isBrowser = () => typeof window !== 'undefined'

    if (isBrowser) {
      const isExternalReferrer =
        document.referrer ||
        !document.referrer.includes(window.location.hostname)

      const noMarketInUrl = window.location.pathname.split('/').length == 2

      if (
        (myMarket &&
          !location.pathname.includes(myMarket?.slug) &&
          !isExternalReferrer) ||
        (myMarket && noMarketInUrl)
      ) {
        navigate(
          getLocaleMarketUrl(
            locale,
            myMarket?.countryCode,
            marketOfferPath(myMarket)
          )
        )
      }
      // else if (!myMarket) navigate(getLocaleUrl(locale, '/angebote'))
    }
  }, [locale])

  let { title, desc } = data.sanityOfferPage.nodes[0]
  if (data?.sanityMarketData) {
    const {
      name,
      address: { city, street },
    } = data.sanityMarketData
    title = `${title} für ${name}, ${street}, ${city}`
  }

  return (
    <>
      <Layout currentMarket={data?.sanityMarketData} translations={languages}>
        <Seo title={title} description={desc} />

        <OfferOverview
          data={{ ...data, ...pageContext }}
          location={location}
          isCurrentOffer
          dividingBannerContent={{
            data: dividingBannerContent,
            market: myMarket,
          }}
        />
      </Layout>
    </>
  )
}

export default OfferPage

export const offers = graphql`
  query (
    $marketId: String!
    $dateToday: Date!
    $campaignEnd: Date!
    $language: String! = "de_de"
    $land: String!
  ) {
    sanityOfferPage: allSanityOfferPage(
      filter: {
        i18n_lang: { eq: $language }
        isNotAvailableInCountry: { ne: true }
      }
    ) {
      nodes {
        offerHandouts {
          ...OfferPdfQuery
        }
        overviewHeadline
        overviewSubline
        noMarketMessage
        myMarketLinkLabel
        overviewSublineMarket
        _rawNoSearchResultsText
        legalDisclaimer
        filterButtonText
        downloadButtonText
        title
        subtitle
        desc
        miniTeaser {
          title
          subline
          url
          urlExtern
          color
          image {
            ...ImageObjectQuery
          }
          reference {
            ... on SanityLandingPage {
              title
              basePath
              slug {
                current
              }
            }
            ... on SanityPost {
              title
              basePath
              category
              slug {
                current
              }
            }
            ... on SanityNewProductFeaturePage {
              title
              slug {
                current
              }
            }
            ... on SanityCareerPage {
              title
              slug {
                current
              }
            }
          }
        }
        dividingBanner {
          _type
          headline
          bioButton {
            ...ButtonQuery
          }
          customCardColor {
            name
            cardColor {
              hex
            }
            textColor
            fieldType
          }

          image {
            ...ImageObjectQuery
          }

          imagePosition
          _rawText
          marketReference {
            ...MarketReferenceQuery
          }
          isFullWidth
        }
      }
    }
    sanityMarketOffers: allSanityOffer(
      filter: {
        hidden: { nin: true }
        marketIdList: { marketIds: { in: [$marketId] } }
        validfrom: { lte: $dateToday }
        validto: { gte: $dateToday }
        land: { eq: $land }
      }
      sort: { fields: [articleGroup___productGroup___order, title, brand] }
      limit: 12
    ) {
      nodes {
        ...OfferQuery
      }
      totalCount
    }

    sanityAllPublishDates: allSanityOffer(
      filter: {
        hidden: { nin: true }
        publishAt: { lte: $dateToday }
        validfrom: { gt: $dateToday }
        land: { eq: $land }
      }
    ) {
      nodes {
        publishAt
        validfrom
        marketIdList {
          marketIds
        }
        global
      }
    }

    sanityAllOffers: allSanityOffer(
      filter: {
        hidden: { nin: true }
        validfrom: { lte: $dateToday }
        validto: { gte: $dateToday }
        land: { eq: $land }
      }
      sort: { fields: [articleGroup___productGroup___order, title, brand] }
      limit: 12
    ) {
      nodes {
        ...OfferQuery
      }
      totalCount
    }

    noLimitOffers: allSanityOffer(
      filter: {
        hidden: { nin: true }
        validfrom: { lte: $dateToday }
        validto: { gte: $dateToday }
        land: { eq: $land }
      }
      sort: { fields: validto }
    ) {
      nodes {
        ...OfferQuery
      }
      totalCount
    }

    sanityMarketData: sanityMarket(marketId: { eq: $marketId }) {
      ...MarketQuery
    }

    sanityGetPreviewCountMarketNoGlobal: allSanityOffer(
      filter: {
        hidden: { nin: true }
        global: { eq: false }
        marketIdList: { marketIds: { in: [$marketId] } }
        validfrom: { gt: $dateToday }
        validto: { lte: $campaignEnd }
        land: { eq: $land }
      }
    ) {
      totalCount
    }

    sanityGetPreviewCountGlobal: allSanityOffer(
      filter: {
        global: { eq: true }
        hidden: { nin: true }
        validfrom: { gt: $dateToday }
        validto: { lte: $campaignEnd }
        land: { eq: $land }
      }
    ) {
      totalCount
    }

    eightPagerGroup: sanityMarketGroup(title: { eq: "8-Seiter" }) {
      market {
        marketId
      }
    }
  }
`
